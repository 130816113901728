import { defineStore } from "pinia";
import { ProfileBlacklist } from "@/features/profileBlacklist/profileBlacklistModels";
import { DefaultPaging, Link, ListResource } from "@/features/shared/models";
import { useCommonStore } from "../shared/commonStore";
import { useAxios } from "../shared/axios";
import { ProfileBlacklistRels } from "./rels";
import { getLink } from "../shared/LinkHelper";
import { Profile } from "../profiles/profilesModels";
import _ from "lodash";

const EmptyProfileBlacklist = { id: '', emailRegex: { pattern: '' } } as ProfileBlacklist;

export const useProfileBlacklistStore = defineStore('profile-blacklist', {
    state: () => ({
        profileBlacklists: new Array<ProfileBlacklist>(),
        currentProfileBlacklist: _.cloneDeep(EmptyProfileBlacklist),
        currentProfileBlacklistLinks: new Array<Link>(),
        currentProfiles: new Array<Profile>(),
        profilesPaging: { ...DefaultPaging },
        links: new Array<Link>(),
    }),
    getters: {
        getProfileBlacklistById: state => {
            return (id: string) => {
                return state.profileBlacklists.find((profileBlacklist: any) => profileBlacklist.id === id) as ProfileBlacklist;
            };
        },
        isValidProfileBlacklist: state => {
            return (
                !!state.currentProfileBlacklist.emailRegex.pattern
            );
        },
    },
    actions: {
        async loadProfileBlacklists() {
            const common = useCommonStore();
            const axios = useAxios();
            const link = common.getLink(ProfileBlacklistRels.GetProfileBlacklists);

            if (!link) throw new Error(`${ProfileBlacklistRels.GetProfileBlacklists} link not found`);

            const response = await axios.requestByLink<ListResource<ProfileBlacklist>>(link);
            this.profileBlacklists = response.data.data;
            this.links = response.data.links;
        },
        async loadProfileBacklist(id: string) {
            let profileBlacklist = this.getProfileBlacklistById(id);
            if (!profileBlacklist) {
                await this.loadProfileBlacklists();
                profileBlacklist = this.getProfileBlacklistById(id);
            }

            await this.loadCurrentProfileBlacklist(profileBlacklist);
        },
        async loadBlacklistedProfiles() {
            const axios = useAxios();
            const link = getLink(ProfileBlacklistRels.GetProfileBlacklistProfiles, this.currentProfileBlacklistLinks);

            const requestParams = new URLSearchParams();
            requestParams.append('page.number', this.profilesPaging.pageNumber.toString());
            requestParams.append('page.size', this.profilesPaging.pageSize.toString());

            const response = await axios.requestByLink(link, {
                params: requestParams
            });
            this.currentProfiles = response.data.data
            this.profilesPaging = response.data.paging;
        },
        async createProfileBlacklist() {
            const common = useCommonStore();
            const axios = useAxios();
            const link = common.getLink(ProfileBlacklistRels.CreateProfileBlacklist);

            if (!link) throw new Error(`${ProfileBlacklistRels.CreateProfileBlacklist} link not found`);

            const profileBlacklistData = { ...this.currentProfileBlacklist };
            await axios.requestByLink(link, { data: profileBlacklistData });
        },
        async updateProfileBlacklist() {
            const axios = useAxios();
            const link = getLink(ProfileBlacklistRels.UpdateProfileBlacklist, this.currentProfileBlacklistLinks);

            const profileBlacklistData = { ...this.currentProfileBlacklist };
            const result = await axios.requestByLink(link, { data: profileBlacklistData });
            this.currentProfileBlacklist = result.data;
        },
        async loadCurrentProfileBlacklist(profileBlacklist: any) {
            const axios = useAxios();
            const link = getLink(ProfileBlacklistRels.GetProfileBlacklist, profileBlacklist.links);
            const response = await axios.requestByLink(link);

            this.currentProfileBlacklist = response.data.data;
            this.currentProfileBlacklistLinks = response.data.links;
        },
        async deleteProfileBlacklist() {
            const axios = useAxios();
            const link = getLink(ProfileBlacklistRels.DeleteProfileBlacklist, this.currentProfileBlacklistLinks);

            await axios.requestByLink(link);
        },
        async deleteBlacklistedProfiles() {
            this.currentProfiles = new Array<Profile>();

            const axios = useAxios();
            const link = getLink(ProfileBlacklistRels.DeleteBlacklistedProfiles, this.currentProfileBlacklistLinks);

            await axios.requestByLink(link);
        },
        clearCurrentProfileBlacklist() {
            this.currentProfileBlacklist = _.cloneDeep(EmptyProfileBlacklist);
            this.currentProfileBlacklistLinks = new Array<Link>();
        }
    }
});