<template>
    <v-container>
        <v-row>
            <v-col>
                <v-row>
                    <v-col><v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs></v-col>
                    <v-col><v-btn class="add-new" color="primary" @click="goToCreateNew()">Ny svarteliste-oppføring</v-btn></v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card>
                            <v-data-table
                                :headers="headers"
                                :items="store.profileBlacklists"
                                :loading="isLoading"
                            >
                            <template #item.controls="{ item }">
                                <router-link :to="{ name: ProfileBlacklistRels.UpdateProfileBlacklist, params: { id: item.raw.id } }">
                                    <v-icon class="edit" icon="mdi-account-edit" />
                                </router-link>
                            </template>
                            </v-data-table>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { VDataTable } from 'vuetify/labs/components';
import { useProfileBlacklistStore } from './profileBlacklistStore';
import router from '@/plugins/router';
import { ProfileBlacklistRels } from './rels';

const store = useProfileBlacklistStore();

const breadcrumbs = [
    {
        title: 'Svarteliste'
    }
];
const headers = [
    {
        title: 'E-post regex-mønster',
        key: 'emailRegex.pattern',
        sortable: true
    },
    {
        title: 'Antall profiler',
        key: 'profileCount',
        sortable: true
    },
    {
        title: '',
        key: 'controls',
        sortable: false
    }
];
const isLoading = ref(true);

onMounted(async () => {
    await store.loadProfileBlacklists();

    isLoading.value = false;
});

function goToCreateNew() {
    router.push({ name: ProfileBlacklistRels.CreateProfileBlacklist });
}
</script>

<style scoped>
.edit {
    color: #000000;
}

.add-new {
    float: right;
    margin-top: 10px;
}
</style>